<template>
    <div>
        <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="changeDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                        :value="value"
                        v-on:change="changeDate"
                        :label="label"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                    :value="value"
                    v-on:change="changeDate"
                    no-title
                    scrollable
            >
                <v-spacer></v-spacer>
                <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                >
                    Закрыть
                </v-btn>
<!--                <v-btn-->
<!--                        text-->
<!--                        color="primary"-->
<!--                        @click="$refs.menu.save(value)"-->
<!--                >-->
<!--                    OK-->
<!--                </v-btn>-->
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>
    export default {
        name: "DatePicker",
        data() {
          return {
              menu: false,
              newDate: null
          }
        },
        props: ['value', 'label'],
        methods: {
            changeDate(date) {
                this.$emit('input', date)
            }
        }
    }
</script>

<style scoped>

</style>
