<template>
    <div style="width: 100%" v-if="!loading">

        <div class="v-label" style="    font-size: 12px;
    opacity: 0.75;">
            Категории
        </div>
        <v-chip-group v-model="chosen_categories" v-on:change="change_categories" column multiple>
            <v-chip filter outlined v-for="item in project_categories.categories" active-class="primary--text text--accent-4">{{item.name}}</v-chip>
        </v-chip-group>
    </div>
    <div style="width: 100%" v-else>Загрузка категорий...</div>
</template>

<script>
    export default {
        name: "ChooseCategory",

        data() {
            return {
                loading: true,
                chosen_categories: [],
            }
        },
        props: ['value'],
        methods: {
            change_categories() {
                this.$emit('input', this.chosen_categories_ids)
            }
        },

        mounted() {
            const that = this;

            if (!this.project_categories){
                this.$store.dispatch('getCategories').then(e => {init() })
            } else {
                init()
            }

            function init() {
                that.loading = false;
                if (that.value && that.value.length) {
                    that.value.forEach(id => {
                        const index = that.project_categories.categories.findIndex(e => e.id === id)
                        that.chosen_categories.push(index)
                    })
                }
            }
        },
        computed: {
            project_categories() {
                return this.$store.state.project_categories
            },
            chosen_categories_ids() {
                return this.chosen_categories.map(e => this.project_categories.categories[e]['id'])
            }
        },
    }
</script>

<style scoped>

</style>
