<template>
    <v-dialog v-model="dialog" width="1000" >
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-on="on" elevation="1"><v-icon class="mr-2">mdi-image-plus</v-icon>{{title ? title : 'Медиа'}}</v-btn>
        </template>

        <Media style="background-color: white; min-height: 50vh" class="px-10 py-3" v-on:arrayOfSizes="arrayOfSizes"></Media>
    </v-dialog>
</template>

<script>
    import Media from "../../views/Media";
    export default {
        name: "MediaDialog",
        components: {Media},
        data() {
            return {
                dialog: false,
            }
        },
        props: ['title'],
        methods: {
            open() {
              this.dialog = true
            },
            arrayOfSizes(e) {
                this.$emit('input', e);
                this.dialog = false
            }
        }
    }
</script>

<style scoped>

</style>
