<template>

    <div>
        <v-app-bar app dark flat>
            <v-tabs>
                <v-tab v-for="(item, index) in languages" :key="index" :to="item">
                    {{ item }}
                </v-tab>
            </v-tabs>
            <div style="margin-right: 24px"><media-dialog/></div>


<!--                    <v-btn v-on:click="setLanguage(data, true)" color="green" elevation="0" large>Сохранить</v-btn>-->
<!--            <v-btn v-on:click="$refs.contentBuilder.showHtml()" color="gray" elevation="0" large>< / ></v-btn>-->
<!--            <v-btn  color="primary" elevation="0" large>Посмотреть</v-btn>-->

<!--            <v-progress-circular v-if="saving" :size="50" color="green" indeterminate></v-progress-circular>-->
            <v-btn v-if="isContentChanged()" v-on:click="setLanguage(data, true)" color="green" :loading="saving" elevation="0" large>Сохранить</v-btn>
            <v-btn v-else color="green" elevation="0"  disabled large>Сохранить</v-btn>

        </v-app-bar>

        <div v-if="data">
            <v-card class="py-5 mb-10 px-6" color="#f3f3f3" elevation="0">
                <v-text-field style="font-size: 24px" v-model="data.title" label="Заголовок" filled></v-text-field>

                <v-row no-gutters fluid class="spacing-playground p-0">
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="data.slug" label="Slug" filled dense></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" class="pl-lg-10">
                        <DatePicker v-model="data.date"  label="Дата публикации" />
                    </v-col>
                    <v-col cols="12" sm="3" class="pl-lg-10">
                        <v-switch v-model="data.publish" color="green" inset :label="isDateFuture(data.date) ? 'Публиковать' : 'Будет опубликовано: '+ data.date"></v-switch>
                    </v-col>



                    <template v-if="$route.params.collection === 'projects'">
                        <v-row>
                            <v-col style="max-width: 180px">
                                <!--Кавер-->
                                <div style="display: none"><MediaDialog ref="media" v-model="setCoverImage" title="Обложка"/></div>
                                <div class="cover" v-on:click="$refs.media.open()">
                                    <v-img class="img" v-if="data.coverImage" :src="data.coverImage['300x300']"><template v-slot:placeholder><v-row class="fill-height ma-0" align="center" justify="center"><v-progress-circular indeterminate></v-progress-circular></v-row></template></v-img>
                                    <div v-else class="text">Установить изображение записи</div>
                                </div>
                            </v-col>
                            <v-col>
                                <ChooseCategory v-model="data.categories" />
                                <v-combobox v-model="data.points" :items="data.points" label="Points" multiple chips></v-combobox>
                                <v-textarea filled name="input-7-4" label="Краткое описание" class="text-area"  v-model="data.description"></v-textarea>
                            </v-col>
                        </v-row>





                    </template>

                </v-row>

            </v-card>

            <ContentBuilder v-model="data.html" v-on:changed="builderChanged" ref="contentBuilder" />

        </div>
        <Loader v-else />
    </div>


</template>

<script>
    import Loader from "../components/ui/Loader";
    import { db, Timestamp} from '../firebase';
    import DatePicker from "../components/ui/DatePicker";
    import ContentBuilder from "../components/ContentBuilder";
    import MediaImageUploader from "../components/ui/MediaImageUploader";
    import ChooseCategory from "../components/ui/ChooseCategory";
    import MediaDialog from "../components/ui/MediaDialog";

    export default {
        name: "Editor",
        components: {
            MediaDialog,
            ChooseCategory,
            MediaImageUploader,
            ContentBuilder,
            DatePicker,
            Loader,
        },

        data() {
            return {
                data: null,
                id: this.$route.params.id,
                collection: this.$route.params.collection,
                language: this.$route.params.lang,
                languages: this.$store.state.languages['languages'],
                zeroState: null,
                saving: false,
                isBuilderChanged: false,

            }
        },
        computed: {
            setCoverImage: {
                get(){},
                set(newVal){
                    let imgObj = {};
                    this.$store.state.imageSizes.forEach(size => {
                        imgObj[size] = newVal[size].url
                    })
                    this.$set(this.data, 'coverImage', imgObj)
                }
            }
        },
        methods: {
            builderChanged(e) {
                this.isBuilderChanged = !e
            },
            isContentChanged() {
                return this.data != null && ((this.compareObjects(this.data, this.zeroState)) || this.isBuilderChanged);
            },
            async getPage(){
                const doc = await db.collection(this.collection).doc(this.id).get();
                if (!doc.exists) {
                    this.$store.commit('alert', { message: 'Страница '+this.id+' не найдена', type: 'error'});
                } else {
                    const data = doc.data()[this.language];
                    if (data) {
                        // from timestemp
                        // console.log(data.dd.toDate().toISOString().substr(0, 10))

                        // to timestemp
                        // console.log(Timestamp.fromDate(new Date('2021-03-17')))
                        // console.log(data.date.toDate().toISOString().substr(0, 10))
                        data.date = data.date.toDate().toISOString().substr(0, 10)

                        this.data = data;

                        this.zeroState = this.cloneObject(data);
                    } else if (this.languages.includes(this.language)) {
                        this.setLanguage({date: new Date().toISOString().substr(0, 10), slug: '', publish: false, html: '', title: ''}, false)
                    }
                }
            },
            async setLanguage(data, isGetHtml) {
                let that = this;
                this.saving = true;

                if (isGetHtml) await this.$refs.contentBuilder.getHtml();


                this.getSlug(data);

                if (isGetHtml) {
                    this.isSlugDuplicated().then(function (e) {
                        if (e) {
                            data.slug = data.slug+'-2';
                            that.setLanguage(data, isGetHtml)
                        } else {
                            save()
                        }
                    });
                } else {
                    save()
                }


                function save() {
                    let obj = {};
                    obj[that.language] = that.cloneObject(data);

                    obj[that.language].date = Timestamp.fromDate(new Date(obj[that.language].date))

                    db.collection(that.collection).doc(that.id).set(obj, { merge: true }).then(function () {
                        that.saving = false;
                        that.data = data;
                        that.zeroState = that.cloneObject(data);
                        that.$store.commit('alert', { message: 'Страница обновлена.'});

                    });
                }

            },
            async isSlugDuplicated() {
                const res = await db.collection(this.collection)
                    .where(this.language+".slug", "==", this.data.slug.toString())
                    .where("id", "!=", this.id.toString())
                    .get()
                return !res.empty;
            },
            getSlug(data) {

                if(data.title && data.title !== '' && (data.slug === '' || !data.slug)) data.slug = this.cyr_to_latin(data.title);
                if((!data.title || data.title === '') && (data.slug === '' || !data.slug)) data.slug = this.id;
                if(data.title !== '' && !isNaN(data.slug)) data.slug = this.cyr_to_latin(data.title);
                data.slug = this.cyr_to_latin(data.slug);

            },
            cyr_to_latin ( str ) {
                const ru = {
                    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
                    'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
                    'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
                    'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
                    'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
                    'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya',
                    'ґ': 'g', 'є': 'ye', 'ї': 'yi',
                }, n_str = [];

                str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');

                for ( var i = 0; i < str.length; ++i ) {
                    n_str.push(
                        ru[ str[i] ]
                        || ru[ str[i].toLowerCase() ] == undefined && str[i]
                        || ru[ str[i].toLowerCase() ].toUpperCase()
                    );
                }

                return n_str.join('').toLowerCase().replace(/[^\w\s\-]/g, '').replace(' ', '-').replace(' ', '');
            },
            leavePageModal(to, from, next) {
                if (this.isContentChanged()) {
                    if (confirm('Хотите уйти не сохранив?')) {
                        this.language = to.params.lang;
                        this.data = null;
                        this.zeroState = null;
                        this.saving = false;
                        this.getPage();
                        next();
                    }
                } else {
                    this.language = to.params.lang;
                    this.data = null;
                    this.zeroState = null;
                    this.saving = false;
                    this.getPage();
                    next();
                }
            },
            isDateFuture(date) {
                var d1 = new Date();
                var d2 = new Date(date);
                return d1.getTime() > d2.getTime();
            }
        },

        mounted() {
            if (!this.language) {
                this.$router.push('/'+this.collection+'/'+this.id+'/'+this.$store.state.languages['main_language'])
                this.language = this.$store.state.languages['main_language']
            }
            this.getPage();
        },


        created() {
          this.builder = this.$refs.contentBuilder;
            this.isContentChanged();

        },
        beforeRouteUpdate (to, from, next) {
            this.leavePageModal(to, from, next)
        },
        beforeRouteLeave(to, from, next) {
            this.leavePageModal(to, from, next)
        },
    }

</script>

<style scoped>
    .cover {
        background-color: #e5e5e5;
        height: 200px;
        width: 150px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        line-height: 1.2em;
        font-size: 14px;
        transition: .3s;
        cursor: pointer;
    }
    .cover:hover {
        background-color: #d5d5d5;
    }
    .cover .text {
        margin: 16px;
    }
    .cover .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .text-area.v-textarea >>> textarea {
        height: 32px;
        transition: .5s;
    }
    .text-area.v-textarea.v-input--is-focused >>> textarea {
        height: 200px;
    }
    >>> .text-area .v-text-field__details {
        display: none;
    }
</style>
